@import "swiper/swiper-bundle.css";
@import "react-datepicker/dist/react-datepicker.css";

@import "@uikit";

@import "@ui/uikit/styles/globals";

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker-popper {
  z-index: 20 !important;
}
